<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  listReturnAPI,
  listReturnSummaryAPI,
  listGoodsReturnAPI,
  listGoodsReturnSummaryAPI,
  listShopReturnQuery,
  listShopReturnQuerySummary,
  listShopCategoryReturnQuery,
  listShopCategoryReturnQuerySummary,
} from "@/api/shop/analyse/posBack"; // 门店退款
import { getDicts } from "@/api/system/dict/data";
export default {
  name: "PosReserveQuery",
  dicts: ["fetch_type"],
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "门店客流量查询",
        rowKey: "billIda",
        search: [
          {
            label: "退货时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称/商品编码/商品名称/条码/退货小票号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编号" },
              { filter: "shopNames", label: "门店名称" },
              { filter: "goodsNos", label: "商品编码" },
              { filter: "goodsNames", label: "商品名称" },
              { filter: "barcodes", label: "条码" },
              { filter: "backBillNos", label: "退货小票号" },
            ],
          },
          this.$select({
            key: "listCashier",
            option: {
              filter: "cashierIds",
              label: "收银员",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收银员",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "cashier" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              label: "商品",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              label: "门店",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "back",
        tabsColumns: [
          {
            // defaultBody: { searchType: 'detail' },
            getListApi: listReturnAPI,
            getSummaryApi: listReturnSummaryAPI,
            uuid: "e8dc4bf0-aedd-2697-f272-1233f13e37ec",
            title: "门店退货明细查询",
            type: "back",
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/sale/analysis/listReturnAnalysisDetailExport",
              exportName: "门店退货明细查询",
            },
            columns: [
              {
                prop: "billDateStr",
                label: "退货时间",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 155,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 155,
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "billNo",
                label: "销售小票号",
                minWidth: 180,
              },
              {
                prop: "backBillNo",
                label: "退货小票号",
                minWidth: 180,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 120,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "商品规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "售价",
                minWidth: 150,
              },
              {
                prop: "unitMoney",
                label: "销售金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "agioMoney",
                label: "优惠金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitMoney", "agioMoney",'unitQty'],
          },
          {
            // defaultBody: { searchType: 'detail' },
            getListApi: listShopCategoryReturnQuery,
            getSummaryApi: listShopCategoryReturnQuerySummary,
            uuid: "dda76d32-2de9-4c21-0959-c4dc0664b8c4",
            title: "门店退货汇总查询（按类别）",
            type: "categoryBack",
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/sale/analysis/listShopCategoryReturnQueryExport",
              exportName: "门店退货汇总查询（按类别）",
            },
            columns: [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 120,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
              },
              {
                prop: "unitMoney",
                label: "销售金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "agioMoney",
                label: "优惠金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitMoney", "agioMoney",'unitQty'],
          },
          {
            // defaultBody: { searchType: 'detail' },
            getListApi: listGoodsReturnAPI,
            getSummaryApi: listGoodsReturnSummaryAPI,
            uuid: "76e461e7-cc2a-cbb0-c17b-53e8e26d0228",
            title: "门店退货汇总查询（按商品）",
            type: "goodsback",
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/sale/analysis/listGoodsReturnQueryExport",
              exportName: "门店退货汇总查询（按商品）",
            },
            columns: [
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "商品规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
              },
              {
                prop: "unitMoney",
                label: "销售金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "agioMoney",
                label: "优惠金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitMoney", "agioMoney",'unitQty'],
          },
          {
            // defaultBody: { searchType: 'detail' },
            getListApi: listShopReturnQuery,
            getSummaryApi: listShopReturnQuerySummary,
            uuid: "db1e4888-578e-62b3-a37f-95f4d91ac1ed",
            title: "门店退货汇总查询（按门店）",
            type: "shopBack",
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/sale/analysis/listShopReturnQueryExport",
              exportName: "门店退货汇总查询（按门店）",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 155,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 155,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
              },
              {
                prop: "unitMoney",
                label: "销售金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "agioMoney",
                label: "优惠金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitMoney", "agioMoney",'unitQty'],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        default:
          break;
      }
    },
  },
};
</script>
