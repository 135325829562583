<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { saleGainAnalysisAPI, saleGainAnalysisSummaryAPI } from '@/api/sale/analyse/grossProfit' //销售毛利分析

export default {
  name: "grossProfit",
  components: { TablePage },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        uuid:'60f6eccb-1db0-d419-ec4f-41031c4dc889',
        body: {},
        title: "销售订单汇总表",
        labelWidth: "80",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "input",
            tip:"商品编码、商品名称、商品分类编号、商品分类名称",
            model: "",
            filter: "query",
          },
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),

          this.$select({
            key: "listCustomer",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "partnerFun",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        getListApi: saleGainAnalysisAPI,
        getSummaryApi: saleGainAnalysisSummaryAPI,
        tabsColumns: [
          {
            title: "商品汇总查询",
            defaultBody: { searchType: "goods" },
            type: "goods",
            uuid:'60f6eccb-1db0-d419-ec4f-41031c4dc889',
            columns: [
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "partnerNo",
                label: "客户编码",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "客户名称",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "billQty",
                label: "销售数量",
                minWidth: 150,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
              },
              {
                prop: "costMoney",
                label: "成本金额",
                minWidth: 150,
              },
              {
                prop: "saleGainMoney",
                label: "毛利金额",
                minWidth: 150,
              },
              {
                prop: "gainRate",
                label: "毛利率%",
                minWidth: 150,
              },
            ],
            summary: ["billQty",'unitMoney','costMoney','saleGainMoney','gainRate'],
          },
          {
            title: "客户汇总查询",
            defaultBody: { searchType: "partner" },
            type: "partner",
            uuid:'f4b03bed-eefd-a265-eacf-d09a1e60701e',
            columns: [
              {
                prop: "partnerNo",
                label: "客户编码",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "客户名称",
                minWidth: 150,
              },
              {
                prop: "billQty",
                label: "销售数量",
                minWidth: 150,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
              },
              {
                prop: "costMoney",
                label: "成本金额",
                minWidth: 150,
              },
              {
                prop: "saleGainMoney",
                label: "毛利金额",
                minWidth: 150,
              },
              {
                prop: "gainRate",
                label: "毛利率%",
                minWidth: 150,
              },
            ],
            summary: ["billQty",'unitMoney','costMoney','saleGainMoney','gainRate'],
          },
          {
            title: "商品类别汇总查询",
            defaultBody: { searchType: "goodsCategory" },
            type: "goodsCategory",
            uuid:'55be290f-8150-de3a-fe6d-f6aabbc7247b',
            columns: [
              {
                prop: "categoryNo",
                label: "类别编码",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "billQty",
                label: "销售数量",
                minWidth: 150,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
              },
              {
                prop: "costMoney",
                label: "成本金额",
                minWidth: 150,
              },
              {
                prop: "saleGainMoney",
                label: "毛利金额",
                minWidth: 150,
              },
              {
                prop: "gainRate",
                label: "毛利率%",
                minWidth: 150,
              },
            ],
            summary: ["billQty",'unitMoney','costMoney','saleGainMoney','gainRate'],
          },
          {
            title: "业务员汇总查询",
            defaultBody: { searchType: "employee" },
            type: "employee",
            uuid:'b5e9ba4d-50d6-a7a0-d8f2-ce054b4d0317',
            columns: [
              {
                prop: "employeeNo",
                label: "业务员编码",
                minWidth: 150,
              },
              {
                prop: "employeeName",
                label: "业务员名称",
                minWidth: 150,
              },
              {
                prop: "billQty",
                label: "销售数量",
                minWidth: 150,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
              },
              {
                prop: "costMoney",
                label: "成本金额",
                minWidth: 150,
              },
              {
                prop: "saleGainMoney",
                label: "毛利金额",
                minWidth: 150,
              },
              {
                prop: "gainRate",
                label: "毛利率%",
                minWidth: 150,
              },
            ],
            summary: ["billQty",'unitMoney','costMoney','saleGainMoney','gainRate'],
          },
        ],
      },
    };
  },
};
</script>
